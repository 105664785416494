import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tabs,
  Tab,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import { Plus, Trash2 } from 'lucide-react';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function Skills() {
  const [skills, setSkills] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [newSkill, setNewSkill] = useState({
    name: '',
    description: '',
    tools: []
  });
  const [currentTool, setCurrentTool] = useState({
    name: '',
    description: '',
    parameters: ''
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setTabValue(0);
    setNewSkill({
      name: '',
      description: '',
      tools: []
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleAddTool = () => {
    if (currentTool.name && currentTool.description) {
      setNewSkill(prev => ({
        ...prev,
        tools: [...prev.tools, { ...currentTool }]
      }));
      setCurrentTool({
        name: '',
        description: '',
        parameters: ''
      });
    }
  };

  const handleRemoveTool = (index) => {
    setNewSkill(prev => ({
      ...prev,
      tools: prev.tools.filter((_, i) => i !== index)
    }));
  };

  const handleSaveSkill = () => {
    if (newSkill.name && newSkill.description) {
      setSkills(prev => [...prev, { ...newSkill }]);
      handleCloseDialog();
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Box sx={{ mb: 3 }}>
        <Button
          variant="contained"
          startIcon={<Plus size={20} />}
          onClick={handleOpenDialog}
        >
          ADD NEW SKILL
        </Button>
      </Box>

      {/* Skills List */}
      <Box sx={{ display: 'grid', gap: 2 }}>
        {skills.map((skill, index) => (
          <Card key={index}>
            <CardContent>
              <Typography variant="h6">{skill.name}</Typography>
              <Typography color="textSecondary">{skill.description}</Typography>
              <Typography variant="subtitle2" sx={{ mt: 2 }}>
                Tools: {skill.tools.length}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>

      {/* Add Skill Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>Add New Skill</DialogTitle>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Skill Details" />
              <Tab label="Tools" />
            </Tabs>
          </Box>

          <TabPanel value={tabValue} index={0}>
            <TextField
              fullWidth
              label="Skill Name"
              value={newSkill.name}
              onChange={(e) => setNewSkill(prev => ({ ...prev, name: e.target.value }))}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Description"
              value={newSkill.description}
              onChange={(e) => setNewSkill(prev => ({ ...prev, description: e.target.value }))}
              margin="normal"
              multiline
              rows={4}
            />
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                label="Tool Name"
                value={currentTool.name}
                onChange={(e) => setCurrentTool(prev => ({ ...prev, name: e.target.value }))}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Tool Description"
                value={currentTool.description}
                onChange={(e) => setCurrentTool(prev => ({ ...prev, description: e.target.value }))}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Parameters (JSON)"
                value={currentTool.parameters}
                onChange={(e) => setCurrentTool(prev => ({ ...prev, parameters: e.target.value }))}
                margin="normal"
                multiline
                rows={4}
              />
              <Button
                variant="outlined"
                onClick={handleAddTool}
                sx={{ mt: 2 }}
              >
                Add Tool
              </Button>
            </Box>

            <List>
              {newSkill.tools.map((tool, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={tool.name}
                    secondary={tool.description}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={() => handleRemoveTool(index)}>
                      <Trash2 size={20} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSaveSkill} variant="contained">
            Save Skill
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
