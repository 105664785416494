import React from 'react';
import { Check } from 'lucide-react';

const HowItWorksSection = () => {
  return (
    <section id="how-it-works" className="py-20 bg-black text-white">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">How Aivira Works</h2>
          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            Our AI agents handle your business communication end-to-end
          </p>
        </div>
        
        {/* Step 1 */}
        <div className="mb-24">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <div className="inline-block bg-purple-600/10 rounded-lg px-4 py-2 mb-4">
                <span className="text-purple-400 font-medium">Step 1</span>
              </div>
              <h3 className="text-2xl font-bold mb-4">Deciding What to Do</h3>
              <p className="text-gray-400 text-lg mb-6">
                AI workers continuously scan for opportunities to take action:
              </p>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <div className="bg-purple-600/10 p-2 rounded-lg mr-4 mt-1">
                    <svg className="w-5 h-5 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4"/>
                    </svg>
                  </div>
                  <div>
                    <h4 className="font-medium mb-2">Incoming Communications</h4>
                    <p className="text-gray-400">Monitors phone calls, emails, and messages in real-time</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="bg-purple-600/10 p-2 rounded-lg mr-4 mt-1">
                    <svg className="w-5 h-5 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                    </svg>
                  </div>
                  <div>
                    <h4 className="font-medium mb-2">Task Management</h4>
                    <p className="text-gray-400">Identifies and prioritizes tasks that need attention</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="bg-gradient-to-br from-purple-900/20 to-blue-900/20 rounded-xl p-6">
              <img src="/images/ai-decision-flow.png" alt="AI Decision Making Flow" className="w-full rounded-lg" />
            </div>
          </div>
        </div>

        {/* Step 2 */}
        <div className="mb-24">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="order-2 md:order-1 bg-gradient-to-br from-purple-900/20 to-blue-900/20 rounded-xl p-6">
              <img src="/images/ai-conversation-flow.png" alt="AI Conversation Flow" className="w-full rounded-lg" />
            </div>
            <div className="order-1 md:order-2">
              <div className="inline-block bg-purple-600/10 rounded-lg px-4 py-2 mb-4">
                <span className="text-purple-400 font-medium">Step 2</span>
              </div>
              <h3 className="text-2xl font-bold mb-4">Having Dynamic Conversations</h3>
              <p className="text-gray-400 text-lg mb-6">
                AI workers engage in natural, context-aware conversations:
              </p>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <div className="bg-purple-600/10 p-2 rounded-lg mr-4 mt-1">
                    <svg className="w-5 h-5 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"/>
                    </svg>
                  </div>
                  <div>
                    <h4 className="font-medium mb-2">Natural Communication</h4>
                    <p className="text-gray-400">Handles calls, messages, and emails with human-like understanding</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="bg-purple-600/10 p-2 rounded-lg mr-4 mt-1">
                    <svg className="w-5 h-5 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"/>
                    </svg>
                  </div>
                  <div>
                    <h4 className="font-medium mb-2">Smart Responses</h4>
                    <p className="text-gray-400">Provides relevant information and takes appropriate actions</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Step 3 */}
        <div>
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <div className="inline-block bg-purple-600/10 rounded-lg px-4 py-2 mb-4">
                <span className="text-purple-400 font-medium">Step 3</span>
              </div>
              <h3 className="text-2xl font-bold mb-4">Keeping Things Moving</h3>
              <p className="text-gray-400 text-lg mb-6">
                AI workers ensure continuous workflow progression:
              </p>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <div className="bg-purple-600/10 p-2 rounded-lg mr-4 mt-1">
                    <svg className="w-5 h-5 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"/>
                    </svg>
                  </div>
                  <div>
                    <h4 className="font-medium mb-2">Automated Workflows</h4>
                    <p className="text-gray-400">Updates systems, logs data, and triggers next steps automatically</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="bg-purple-600/10 p-2 rounded-lg mr-4 mt-1">
                    <svg className="w-5 h-5 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>
                    </svg>
                  </div>
                  <div>
                    <h4 className="font-medium mb-2">Seamless Handoffs</h4>
                    <p className="text-gray-400">Coordinates between AI workers and human team members when needed</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="bg-gradient-to-br from-purple-900/20 to-blue-900/20 rounded-xl p-6">
              <img src="/images/ai-workflow.png" alt="AI Workflow Automation" className="w-full rounded-lg" />
            </div>
          </div>
        </div>

        <div className="mt-28">
          <h2 className="text-3xl font-bold mb-16 text-center bg-gradient-to-r from-purple-600 to-blue-500 text-transparent bg-clip-text">
            Aivira: The Future of Business Communication
          </h2>
          <div className="flex flex-col items-center space-y-6">
            {[
              "Handles both inbound & outbound calls 24/7",
              "Automates repetitive data entry tasks",
              "Reduces operational costs by up to 80%",
              "Frees up staff for high-value work"
            ].map((benefit, index) => (
              <div key={index} className="bg-white rounded-full px-10 py-5 shadow-lg flex items-center">
                <Check className="text-green-500 mr-5" size={24} />
                <span className="text-lg font-medium">{benefit}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorksSection;