import React, { useState, useEffect } from 'react';
import { ChevronRight, Phone, Building2, Users, Sparkles, Check, Sun, Moon } from 'lucide-react';

const LandingPage = () => {
  const [darkMode, setDarkMode] = useState(true);
  
  useEffect(() => {
    // Check if user has a theme preference in localStorage
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'light') {
      setDarkMode(false);
      document.documentElement.classList.remove('dark');
    } else {
      setDarkMode(true);
      document.documentElement.classList.add('dark');
    }
  }, []);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    if (!darkMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
  };

  const [redirectingStates, setRedirectingStates] = useState({
    login: false,
    trial1: false,
    trial2: false,
    trial3: false,
    trial4: false,
    trial5: false
  });

  const industries = [
    {
      icon: <Building2 size={28} className="text-purple-600" />,
      title: "Hotels",
      description: "Handle reservations & room service requests 24/7",
      url: "https://try.aivira.co/hotels"
    },
    {
      icon: <Users size={28} className="text-purple-600" />,
      title: "Restaurants",
      description: "Manage bookings & takeout orders efficiently",
      url: "https://try.aivira.co/restaurants"
    },
    {
      icon: <Sparkles size={28} className="text-purple-600" />,
      title: "Resorts",
      description: "Coordinate activities & guest experiences",
      url: "https://try.aivira.co/resorts"
    },
    {
      icon: <Building2 size={28} className="text-purple-600" />,
      title: "Vacation Rentals",
      description: "Handle inquiries & booking management",
      url: "https://try.aivira.co/vacation-rentals"
    },
    {
      icon: <Building2 size={28} className="text-purple-600" />,
      title: "Event Venues",
      description: "Manage event bookings & client coordination",
      url: "https://try.aivira.co/event-venues"
    },
    {
      icon: <Building2 size={28} className="text-purple-600" />,
      title: "Concierge Services",
      description: "Provide 24/7 guest assistance & recommendations",
      url: "https://try.aivira.co/concierge"
    },
    {
      icon: <Users size={28} className="text-purple-600" />,
      title: "Travel Agencies",
      description: "Handle travel inquiries & booking coordination",
      url: "https://try.aivira.co/travel-agencies"
    },
    {
      icon: <Building2 size={28} className="text-purple-600" />,
      title: "Cruise Lines",
      description: "Manage bookings & passenger services",
      url: "https://try.aivira.co/cruise-lines"
    }
  ];

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 transition-colors">
      {/* Navigation */}
      <nav className="fixed w-full bg-white/95 dark:bg-gray-900/95 backdrop-blur-sm z-50 border-b border-gray-100 dark:border-gray-800">
        <div className="max-w-7xl mx-auto px-8 py-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-14">
              <div className="flex items-center space-x-3">
                <Phone className="w-7 h-7 text-purple-600" />
                <span className="text-xl font-semibold text-gray-900 dark:text-white">Aivira</span>
              </div>
              <div className="hidden md:flex space-x-10">
                <a href="#how-it-works" className="text-gray-600 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 text-sm font-medium">How it works</a>
                <a href="#features" className="text-gray-600 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 text-sm font-medium">Features</a>
                <a href="#pricing" className="text-gray-600 dark:text-gray-300 hover:text-purple-600 dark:hover:text-purple-400 text-sm font-medium">Pricing</a>
              </div>
            </div>
            <div className="flex items-center space-x-6">
              <button
                onClick={toggleDarkMode}
                className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
                aria-label="Toggle dark mode"
              >
                {darkMode ? (
                  <Sun className="w-5 h-5 text-gray-600 dark:text-gray-400" />
                ) : (
                  <Moon className="w-5 h-5 text-gray-600" />
                )}
              </button>
              <button 
                className="text-sm font-medium text-gray-600 hover:text-purple-600"
                onClick={() => {
                  setRedirectingStates(prev => ({ ...prev, login: true }));
                  if (typeof window !== 'undefined') {
                    const accessToken = localStorage.getItem('accessToken');
                    if (accessToken) {
                      window.location.href = '/dashboard';
                    } else {
                      window.open('https://api.aivira.co/login', '_self');
                    }
                  }
                }}
              >
                {redirectingStates.login ? 'Redirecting...' : 'Login'}
              </button>
              <button 
                className="px-5 py-2 text-sm font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-700 transition-colors"
                onClick={() => {
                  setRedirectingStates(prev => ({ ...prev, trial1: true }));
                  if (typeof window !== 'undefined') {
                    window.open('https://api.aivira.co/login', '_self');
                  }
                }}
              >
                {redirectingStates.trial1 ? 'Redirecting...' : 'Get Started Free'}
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="pt-36 pb-28 px-6">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-4xl md:text-[64px] font-bold text-gray-900 dark:text-white leading-[1.1] mb-8">
            AI Agents for{' '}
            <div className="text-purple-600 dark:text-purple-400">
              Exceptional Guest<br />
              Experiences
            </div>
          </h1>
          <p className="text-lg md:text-xl text-gray-600 dark:text-gray-300 mb-12 max-w-2xl mx-auto">
            Automate data entry tasks with AI-powered inbound & outbound calls, freeing your staff to focus on high-value guest interactions
          </p>
          <div className="flex flex-wrap justify-center gap-6 mb-12">
            <div className="flex items-center space-x-3">
              <Check className="text-green-500" size={20} />
              <span className="text-gray-700 dark:text-gray-300">Automated Inbound Calls</span>
            </div>
            <div className="flex items-center space-x-3">
              <Check className="text-green-500" size={20} />
              <span className="text-gray-700 dark:text-gray-300">Proactive Outbound Calls</span>
            </div>
            <div className="flex items-center space-x-3">
              <Check className="text-green-500" size={20} />
              <span className="text-gray-700 dark:text-gray-300">Automated Data Entry</span>
            </div>
          </div>
          <button 
            className="px-10 py-4 text-base font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-700 transition-all shadow-sm hover:shadow-md"
            onClick={() => {
              setRedirectingStates(prev => ({ ...prev, trial2: true }));
              if (typeof window !== 'undefined') {
                window.open('https://api.aivira.co/login', '_self');
              }
            }}
          >
            {redirectingStates.trial2 ? 'Redirecting...' : 'Get Started for Free'}
          </button>
          <p className="text-lg text-gray-600 mt-8">
            First 60 minutes completely free. No credit card required.
          </p>

        </div>
      </div>

      {/* Industry Solutions */}
      <div id="features" className="bg-gray-50 dark:bg-gray-800 py-28 px-6">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-20">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-6">
              AI-Powered Solutions for Modern Hospitality
            </h2>
          </div>
          <div className="grid md:grid-cols-3 gap-12">
            {/* Intelligent Booking Management */}
            <div className="bg-white dark:bg-gray-900 p-8 rounded-xl border border-gray-100 dark:border-gray-700">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Intelligent Booking Management</h3>
              <p className="text-gray-600 dark:text-gray-400 mb-6">
                AI agents handle reservation inquiries, modify bookings, and process cancellations 24/7, ensuring your guests receive immediate assistance.
              </p>
              <div className="space-y-3">
                <div className="flex items-center space-x-3">
                  <Check className="text-purple-600 dark:text-purple-400" size={20} />
                  <span className="text-gray-700 dark:text-gray-300">Real-time Availability Updates</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Check className="text-purple-600 dark:text-purple-400" size={20} />
                  <span className="text-gray-700 dark:text-gray-300">Smart Room Assignment</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Check className="text-purple-600 dark:text-purple-400" size={20} />
                  <span className="text-gray-700 dark:text-gray-300">Automated Confirmation Emails</span>
                </div>
              </div>
            </div>

            {/* Virtual Concierge Services */}
            <div className="bg-white dark:bg-gray-900 p-8 rounded-xl border border-gray-100 dark:border-gray-700">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Virtual Concierge Services</h3>
              <p className="text-gray-600 dark:text-gray-400 mb-6">
                Provide instant responses to guest inquiries about amenities, local attractions, and services while maintaining a personal touch.
              </p>
              <div className="space-y-3">
                <div className="flex items-center space-x-3">
                  <Check className="text-purple-600 dark:text-purple-400" size={20} />
                  <span className="text-gray-700 dark:text-gray-300">Local Recommendations</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Check className="text-purple-600 dark:text-purple-400" size={20} />
                  <span className="text-gray-700 dark:text-gray-300">Service Requests Handling</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Check className="text-purple-600 dark:text-purple-400" size={20} />
                  <span className="text-gray-700 dark:text-gray-300">Multi-language Support</span>
                </div>
              </div>
            </div>

            {/* Operations Automation */}
            <div className="bg-white dark:bg-gray-900 p-8 rounded-xl border border-gray-100 dark:border-gray-700">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Operations Automation</h3>
              <p className="text-gray-600 dark:text-gray-400 mb-6">
                Streamline your hotel operations with automated workflows for check-in/out, housekeeping coordination, and maintenance requests.
              </p>
              <div className="space-y-3">
                <div className="flex items-center space-x-3">
                  <Check className="text-purple-600 dark:text-purple-400" size={20} />
                  <span className="text-gray-700 dark:text-gray-300">Digital Check-in/out</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Check className="text-purple-600 dark:text-purple-400" size={20} />
                  <span className="text-gray-700 dark:text-gray-300">Housekeeping Management</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Check className="text-purple-600 dark:text-purple-400" size={20} />
                  <span className="text-gray-700 dark:text-gray-300">Maintenance Coordination</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* How it Works Section */}
      <div id="how-it-works" className="py-28 px-6 dark:bg-gray-900">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-20">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-6">
              How Aivira Works
            </h2>
            <p className="text-lg text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
              Increase your efficiency and reduce your costs in 3 simple steps
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-12">
            {[
              { 
                title: "Automated Inbound Calls", 
                description: "AI agents handle all incoming guest calls 24/7, from inquiries to requests. Automatically captures and processes data into your hotel management system.", 
                icon: "📞",
                features: [
                  "24/7 Call Handling",
                  "Automated Data Entry",
                  "Real-time System Updates"
                ]
              },
              { 
                title: "Proactive Outbound Calls", 
                description: "Smart AI agents make outbound calls for confirmations, reminders, and follow-ups. Automatically updates reservation systems and guest records.", 
                icon: "📱",
                features: [
                  "Booking Confirmations",
                  "Automated Follow-ups",
                  "Guest Satisfaction Calls"
                ]
              },
              { 
                title: "Data Entry Automation", 
                description: "Eliminate manual data entry tasks by letting AI handle all call-related information processing, freeing your staff for high-value guest interactions.", 
                icon: "⚡",
                features: [
                  "Zero Manual Entry",
                  "Error-free Processing",
                  "Instant System Updates"
                ]
              }
            ].map((step, index) => (
              <div key={index} className="bg-white dark:bg-gray-800 p-10 rounded-2xl shadow-lg">
                <div className="text-center">
                  <div className="text-5xl mb-8">{step.icon}</div>
                  <h3 className="text-2xl font-semibold mb-5 dark:text-white">{step.title}</h3>
                  <p className="text-gray-600 dark:text-gray-300 mb-8">{step.description}</p>
                </div>
                <div className="space-y-3">
                  {step.features.map((feature, fIndex) => (
                    <div key={fIndex} className="flex items-center space-x-3">
                      <div className="flex-shrink-0">
                        <Check className="w-5 h-5 text-purple-600 dark:text-purple-400" />
                      </div>
                      <span className="text-gray-700 dark:text-gray-300">{feature}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="mt-28">
            <h2 className="text-3xl font-bold mb-16 text-center bg-gradient-to-r from-purple-600 to-blue-500 text-transparent bg-clip-text">
              Aivira: The Future of Business Communication
            </h2>
            <div className="flex flex-col items-center space-y-6">
              {[
                "Automated Inbound & Outbound Call Management",
                "Zero Manual Data Entry Required",
                "Seamless Integration with Hotel Systems",
                "Staff Free to Focus on High-Value Tasks"
              ].map((benefit, index) => (
                <div key={index} className="bg-white rounded-full px-10 py-5 shadow-lg flex items-center">
                  <Check className="text-green-500 mr-5" size={24} />
                  <span className="text-lg font-medium">{benefit}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Pricing Section */}
      <div id="pricing" className="bg-gray-50 dark:bg-gray-800 py-28 px-6">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-4 dark:text-white">Choose Your Plan</h2>
            <p className="text-gray-600 dark:text-gray-300 text-lg max-w-2xl mx-auto">
              Start with our free trial and upgrade anytime to match your business needs.
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            {/* Starter Plan */}
            <div className="bg-white dark:bg-gray-900 p-8 rounded-sm border border-gray-200 dark:border-gray-700 hover:border-purple-500 transition-colors relative">
              <div className="mb-8">
                <h3 className="text-xl font-bold mb-2 dark:text-white">Starter</h3>
                <div className="text-3xl font-bold text-purple-600 dark:text-purple-400 mb-2">
                  $300<span className="text-xl font-normal text-gray-600 dark:text-gray-400">/month</span>
                </div>
                <p className="text-gray-600 dark:text-gray-400">Perfect for properties with up to 20 rooms</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">Base fee $200 + $5/room (up to 20 rooms)</p>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center text-gray-700 dark:text-gray-300">
                  <Check className="w-5 h-5 text-purple-600 dark:text-purple-400 mr-3" />
                  500 minutes/month
                </li>
                <li className="flex items-center text-gray-700 dark:text-gray-300">
                  <Check className="w-5 h-5 text-purple-600 dark:text-purple-400 mr-3" />
                  Basic Reservation Management
                </li>
                <li className="flex items-center text-gray-700 dark:text-gray-300">
                  <Check className="w-5 h-5 text-purple-600 dark:text-purple-400 mr-3" />
                  Guest Request Handling
                </li>
                <li className="flex items-center text-gray-700 dark:text-gray-300">
                  <Check className="w-5 h-5 text-purple-600 dark:text-purple-400 mr-3" />
                  Email Confirmations
                </li>
                <li className="flex items-center text-gray-700 dark:text-gray-300">
                  <Check className="w-5 h-5 text-purple-600 dark:text-purple-400 mr-3" />
                  English Language Support
                </li>
                <li className="flex items-center text-gray-700 dark:text-gray-300">
                  <Check className="w-5 h-5 text-purple-600 dark:text-purple-400 mr-3" />
                  Basic Reporting
                </li>
              </ul>
              <button 
                onClick={() => {
                  setRedirectingStates(prev => ({ ...prev, trial3: true }));
                  if (typeof window !== 'undefined') {
                    window.open('https://api.aivira.co/login', '_self');
                  }
                }}
                className="w-full py-3 rounded-sm border border-purple-600 hover:bg-purple-600 hover:text-white text-purple-600 transition-colors font-medium"
              >
                {redirectingStates.trial3 ? 'Redirecting...' : 'Start Free Trial'}
              </button>
            </div>

            {/* Business Plan */}
            <div className="bg-white dark:bg-gray-900 p-8 rounded-sm border border-purple-600 hover:border-purple-700 transition-colors relative transform scale-105">
              <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <span className="bg-purple-600 text-white px-4 py-1 text-sm font-medium">
                  Most Popular
                </span>
              </div>
              <div className="mb-8">
                <h3 className="text-xl font-bold mb-2 dark:text-white">Business</h3>
                <div className="text-3xl font-bold text-purple-600 dark:text-purple-400 mb-2">
                  $500<span className="text-xl font-normal text-gray-600 dark:text-gray-400">/month</span>
                </div>
                <p className="text-gray-600 dark:text-gray-400">Perfect for properties with up to 60 rooms</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">Base fee $200 + $5/room (up to 60 rooms)</p>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center text-gray-700 dark:text-gray-300">
                  <Check className="w-5 h-5 text-purple-600 dark:text-purple-400 mr-3" />
                  1500 minutes/month
                </li>
                <li className="flex items-center text-gray-700 dark:text-gray-300">
                  <Check className="w-5 h-5 text-purple-600 dark:text-purple-400 mr-3" />
                  Advanced Reservation System
                </li>
                <li className="flex items-center text-gray-700 dark:text-gray-300">
                  <Check className="w-5 h-5 text-purple-600 dark:text-purple-400 mr-3" />
                  Full Concierge Services
                </li>
                <li className="flex items-center text-gray-700 dark:text-gray-300">
                  <Check className="w-5 h-5 text-purple-600 dark:text-purple-400 mr-3" />
                  PMS Integration
                </li>
                <li className="flex items-center text-gray-700 dark:text-gray-300">
                  <Check className="w-5 h-5 text-purple-600 dark:text-purple-400 mr-3" />
                  Multiple Language Support
                </li>
                <li className="flex items-center text-gray-700 dark:text-gray-300">
                  <Check className="w-5 h-5 text-purple-600 dark:text-purple-400 mr-3" />
                  Priority Support
                </li>
                <li className="flex items-center text-gray-700 dark:text-gray-300">
                  <Check className="w-5 h-5 text-purple-600 dark:text-purple-400 mr-3" />
                  Advanced Analytics
                </li>
              </ul>
              <button 
                onClick={() => {
                  setRedirectingStates(prev => ({ ...prev, trial4: true }));
                  if (typeof window !== 'undefined') {
                    window.open('https://api.aivira.co/login', '_self');
                  }
                }}
                className="w-full py-3 rounded-sm bg-purple-600 hover:bg-purple-700 text-white transition-colors font-medium"
              >
                {redirectingStates.trial4 ? 'Redirecting...' : 'Start Free Trial'}
              </button>
            </div>

            {/* Enterprise Plan */}
            <div className="bg-white dark:bg-gray-900 p-8 rounded-sm border border-gray-200 dark:border-gray-700 hover:border-purple-500 transition-colors relative">
              <div className="mb-8">
                <h3 className="text-xl font-bold mb-2 dark:text-white">Enterprise</h3>
                <div className="text-3xl font-bold text-purple-600 dark:text-purple-400 mb-2">
                  $800+<span className="text-xl font-normal text-gray-600 dark:text-gray-400">/month</span>
                </div>
                <p className="text-gray-600 dark:text-gray-400">For properties with 100+ rooms</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">Base fee $200 + $5/room (100+ rooms)</p>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center text-gray-700 dark:text-gray-300">
                  <Check className="w-5 h-5 text-purple-600 dark:text-purple-400 mr-3" />
                  Unlimited minutes/month
                </li>
                <li className="flex items-center text-gray-700 dark:text-gray-300">
                  <Check className="w-5 h-5 text-purple-600 dark:text-purple-400 mr-3" />
                  Complete Hotel Management Suite
                </li>
                <li className="flex items-center text-gray-700 dark:text-gray-300">
                  <Check className="w-5 h-5 text-purple-600 dark:text-purple-400 mr-3" />
                  Custom AI Training for Your Brand
                </li>
                <li className="flex items-center text-gray-700 dark:text-gray-300">
                  <Check className="w-5 h-5 text-purple-600 dark:text-purple-400 mr-3" />
                  Multiple Property Management
                </li>
                <li className="flex items-center text-gray-700 dark:text-gray-300">
                  <Check className="w-5 h-5 text-purple-600 dark:text-purple-400 mr-3" />
                  Dedicated Hospitality Manager
                </li>
                <li className="flex items-center text-gray-700 dark:text-gray-300">
                  <Check className="w-5 h-5 text-purple-600 dark:text-purple-400 mr-3" />
                  24/7 VIP Support
                </li>
                <li className="flex items-center text-gray-700 dark:text-gray-300">
                  <Check className="w-5 h-5 text-purple-600 dark:text-purple-400 mr-3" />
                  Advanced Performance Analytics
                </li>
              </ul>
              <button 
                onClick={() => {
                  setRedirectingStates(prev => ({ ...prev, trial5: true }));
                  if (typeof window !== 'undefined') {
                    window.open('https://api.aivira.co/login', '_self');
                  }
                }}
                className="w-full py-3 rounded-sm border border-purple-600 hover:bg-purple-600 hover:text-white text-purple-600 dark:text-purple-400 transition-colors font-medium"
              >
                {redirectingStates.trial5 ? 'Redirecting...' : 'Start Free Trial'}
              </button>
            </div>
          </div>

          <div className="mt-20 text-center">
            <p className="text-xl font-medium mb-10 dark:text-white">All plans include:</p>
            <div className="grid md:grid-cols-3 gap-8 max-w-4xl mx-auto">
              <div className="text-center">
                <div className="text-4xl mb-5">🎯</div>
                <h4 className="text-xl font-semibold mb-3 dark:text-white">No Setup Fee</h4>
                <p className="text-gray-600 dark:text-gray-400">Get started immediately</p>
              </div>
              <div className="text-center">
                <div className="text-4xl mb-5">📞</div>
                <h4 className="text-xl font-semibold mb-3 dark:text-white">14-Day Free Trial</h4>
                <p className="text-gray-600 dark:text-gray-400">Try before you commit</p>
              </div>
              <div className="text-center">
                <div className="text-4xl mb-5">🚀</div>
                <h4 className="text-xl font-semibold mb-3 dark:text-white">Cancel Anytime</h4>
                <p className="text-gray-600 dark:text-gray-400">No long-term contracts</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
