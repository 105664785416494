import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';
import HowItWorksSection from './HowItWorksSection';

const NewLandingPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHeaderRedirecting, setIsHeaderRedirecting] = useState(false);
  const [isHeroRedirecting, setIsHeroRedirecting] = useState(false);
  const [isStarterRedirecting, setIsStarterRedirecting] = useState(false);
  const [isBusinessRedirecting, setIsBusinessRedirecting] = useState(false);
  const [isProRedirecting, setIsProRedirecting] = useState(false);

  const handleStartTrial = (buttonType) => {
    switch(buttonType) {
      case 'header':
        setIsHeaderRedirecting(true);
        break;
      case 'hero':
        setIsHeroRedirecting(true);
        break;
      case 'starter':
        setIsStarterRedirecting(true);
        break;
      case 'business':
        setIsBusinessRedirecting(true);
        break;
      case 'pro':
        setIsProRedirecting(true);
        break;
    }
    if (typeof window !== 'undefined') {
      window.open('https://api.aivira.co/login', '_self');
    }
  };

  return (
    <div className="min-h-screen bg-black">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 bg-black text-white z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-20">
            {/* Logo on the left */}
            <div className="flex items-center">
              <img src="/images/aivira-logo-head.png" alt="Aivira Logo" className="w-8 h-8" />
              <span className="ml-2 text-xl font-bold text-white">Aivira</span>
            </div>
            {/* Navigation and buttons on the right */}
            <div className="flex items-center space-x-8">
              <a href="#features" className="text-white hover:text-gray-300 text-base">Features</a>
              <a href="#pricing" className="text-white hover:text-gray-300 text-base">Pricing</a>
              <a href="https://api.aivira.co/login" className="text-white hover:text-gray-300 text-base">Login</a>
              <Button 
                onClick={() => handleStartTrial('header')}
                className="bg-white hover:bg-gray-100 text-black px-8 py-2 rounded-md text-base"
              >
                {isHeaderRedirecting ? 'Redirecting...' : 'Get Started for Free'}
              </Button>
            </div>
          </div>
        </div>
      </header>

      {/* Hero Section */}
      <section className="pt-36 pb-28 px-6 bg-black text-white">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-6xl md:text-7xl font-bold mb-12 leading-tight">
            <div>Inbound &</div>
            <div className="text-purple-600">Outbound Calls</div>
          </h1>
          <p className="text-xl text-gray-100 mb-12 max-w-3xl mx-auto">
            Let AI handle your routine calls and data entry tasks while your team focuses on high-value work
          </p>
          <div className="flex flex-wrap justify-center gap-12 mb-12">
            <div className="flex items-center space-x-3">
              <svg className="text-green-500 w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <span className="text-gray-100 text-lg">24/7 Call Handling</span>
            </div>
            <div className="flex items-center space-x-3">
              <svg className="text-green-500 w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <span className="text-gray-100 text-lg">Automated Data Entry</span>
            </div>
            <div className="flex items-center space-x-3">
              <svg className="text-green-500 w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <span className="text-gray-100 text-lg">Smart Call Routing</span>
            </div>
          </div>
          <Button 
            onClick={() => handleStartTrial('hero')}
            className="px-12 py-4 text-lg font-medium text-black bg-white hover:bg-gray-100 rounded-md transition-all"
          >
            {isHeroRedirecting ? 'Redirecting...' : 'Get Started for Free'}
          </Button>
          <p className="text-lg text-gray-300 mt-8">
            First 60 minutes completely free. No credit card required.
          </p>
        </div>
      </section>

      {/* How it Works */}
      <HowItWorksSection />

      {/* Features Grid */}
      <section id="features" className="py-20 bg-black text-white">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Powerful Features for Modern Business</h2>
            <p className="text-gray-200 text-lg max-w-2xl mx-auto">
              Everything you need to automate your business communication and provide exceptional customer service.
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                title: "Inbound Call Handling",
                description: "Never miss a call again. AI agents answer instantly, qualify leads, and handle customer service 24/7 with human-like conversations.",
                icon: "📞"
              },
              {
                title: "Outbound Campaigns",
                description: "Automate follow-ups, appointment reminders, and sales calls. Reach more customers without expanding your team.",
                icon: "📱"
              },
              {
                title: "Data Entry Automation",
                description: "Let AI handle form filling, data validation, and CRM updates. Free your team from repetitive tasks.",
                icon: "⚡"
              },
              {
                title: "Smart Call Routing",
                description: "AI qualifies and routes calls based on intent, priority, and team availability. Only handle the conversations that matter.",
                icon: "🎯"
              },
              {
                title: "Custom Workflows",
                description: "Train AI agents for your specific business processes. Handle complex scenarios while maintaining your brand voice.",
                icon: "🔄"
              },
              {
                title: "Business Intelligence",
                description: "Get insights from every conversation. Track performance, identify trends, and optimize your operations.",
                icon: "📊"
              }
            ].map((feature, index) => (
              <div 
                key={index} 
                className="bg-gray-900 p-8 rounded-sm border border-gray-800 hover:border-gray-700 transition-colors"
              >
                <div className="text-4xl mb-6">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
                <p className="text-gray-200 leading-relaxed">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>


      {/* Pricing Section */}
      <section id="pricing" className="py-20 bg-black text-white">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Choose Your Plan</h2>
            <p className="text-gray-200 text-lg max-w-2xl mx-auto">
              Start with our free trial and upgrade anytime to match your business needs.
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                name: "Starter",
                price: "$99",
                description: "Perfect for small businesses starting with AI automation",
                features: [
                  "500 minutes/month",
                  "Inbound Call Handling",
                  "Basic Data Entry Automation",
                  "Email Notifications",
                  "Standard Support",
                  "Basic Analytics"
                ]
              },
              {
                name: "Business",
                price: "$199",
                description: "Ideal for growing businesses needing comprehensive automation",
                features: [
                  "1500 minutes/month",
                  "Inbound & Outbound Calls",
                  "Advanced Data Entry Automation",
                  "CRM Integration",
                  "Custom Workflows",
                  "Priority Support",
                  "Advanced Analytics"
                ],
                popular: true
              },
              {
                name: "Enterprise",
                price: "$399",
                description: "For businesses requiring maximum automation capabilities",
                features: [
                  "Unlimited minutes/month",
                  "Full Automation Suite",
                  "Custom AI Training",
                  "Enterprise Integrations",
                  "Dedicated Success Manager",
                  "24/7 Premium Support",
                  "Custom Reporting"
                ]
              }
            ].map((plan, index) => (
              <div 
                key={index} 
                className={`bg-gray-900 p-8 rounded-sm border ${
                  plan.popular ? 'border-white' : 'border-gray-800'
                } hover:border-gray-700 transition-colors relative`}
              >
                {plan.popular && (
                  <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <span className="bg-white text-black px-4 py-1 text-sm font-medium">
                      Most Popular
                    </span>
                  </div>
                )}
                <div className="mb-8">
                  <h3 className="text-xl font-bold mb-2">{plan.name}</h3>
                  <div className="text-3xl font-bold mb-2">
                    {plan.price}<span className="text-xl font-normal text-gray-400">/month</span>
                  </div>
                  <p className="text-gray-200">{plan.description}</p>
                </div>
                <ul className="space-y-4 mb-8">
                  {plan.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-center text-gray-100">
                      <svg className="w-5 h-5 text-white mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>
                <Button
                  onClick={() => handleStartTrial(plan.name.toLowerCase())}
                  className={`w-full py-3 rounded-sm ${
                    plan.popular
                      ? 'bg-white hover:bg-gray-100 text-black'
                      : 'border border-white hover:bg-white hover:text-black text-white'
                  } transition-colors font-medium`}
                >
                  {plan.name === 'Starter' && isStarterRedirecting ? 'Redirecting...' :
                   plan.name === 'Business' && isBusinessRedirecting ? 'Redirecting...' :
                   plan.name === 'Pro' && isProRedirecting ? 'Redirecting...' :
                   'Start Free Trial'}
                </Button>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-black text-white py-16 border-t border-gray-800">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid md:grid-cols-4 gap-12">
            <div>
              <div className="flex items-center mb-6">
                <img src="/images/aivira-logo-head.png" alt="Aivira Logo" className="w-8 h-8" />
                <span className="ml-2 text-xl font-bold">Aivira</span>
              </div>
              <p className="text-gray-200 text-sm">
                Empowering businesses with AI agents that handle calls and automate data entry tasks 24/7, letting your team focus on what matters most.
              </p>
            </div>
            <div>
              <h3 className="text-sm font-semibold mb-4 text-gray-300">Product</h3>
              <ul className="space-y-3">
                <li><a href="#features" className="text-gray-400 hover:text-white text-sm">Features</a></li>
                <li><a href="#pricing" className="text-gray-400 hover:text-white text-sm">Pricing</a></li>
                <li><a href="#demo" className="text-gray-400 hover:text-white text-sm">Request Demo</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold mb-4 text-gray-300">Company</h3>
              <ul className="space-y-3">
                <li><Link to="/about" className="text-gray-400 hover:text-white text-sm">About</Link></li>
                <li><Link to="/blog" className="text-gray-400 hover:text-white text-sm">Blog</Link></li>
                <li><a href="https://discord.gg/3PQMZs8Rvz" className="text-gray-400 hover:text-white text-sm">Community</a></li>
                <li><Link to="/careers" className="text-gray-400 hover:text-white text-sm">Careers</Link></li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold mb-4 text-gray-300">Legal</h3>
              <ul className="space-y-3">
                <li><Link to="/privacy" className="text-gray-400 hover:text-white text-sm">Privacy Policy</Link></li>
                <li><Link to="/terms" className="text-gray-400 hover:text-white text-sm">Terms of Service</Link></li>
                <li><Link to="/cookie-policy" className="text-gray-400 hover:text-white text-sm">Cookie Policy</Link></li>
                <li><Link to="/gdpr" className="text-gray-400 hover:text-white text-sm">GDPR Compliance</Link></li>
              </ul>
            </div>
          </div>
          <div className="mt-16 pt-8 border-t border-gray-800 flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-400 text-sm">&copy; {new Date().getFullYear()} Aivira. All rights reserved.</p>
            <div className="flex space-x-6 mt-4 md:mt-0">
              <a href="https://twitter.com/aivira" className="text-gray-400 hover:text-white">
                Twitter
              </a>
              <a href="https://linkedin.com/company/aivira" className="text-gray-400 hover:text-white">
                LinkedIn
              </a>
              <a href="https://github.com/aivira" className="text-gray-400 hover:text-white">
                GitHub
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default NewLandingPage;
